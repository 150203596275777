<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import MessageModal from "@/components/modals/message-modal";
import SaveButton from "@/components/buttons/save-button";
import PreviousButton from "@/components/buttons/previous-button";

export default {
	components: {
		MessageModal,
		SaveButton,
		PreviousButton
	},
	name: "tabs-services",
	props: {
		tutorInfo: Object,
		serviceOptions: Array,
		serviceProviderOptions: Array,
	},
	data() {
		const id = this.$route.params.id;
        let modeName;
		
		if (this.$route.path.includes('view')) {
			modeName = 'View';
		} else {
			modeName = id === 'add' ? 'Add' : 'Edit';
		}

		return {
			id: id,
			modeName,
			data: {
				services: [],
				service_providers: [],
			},
			dataReady: false,
			selectedServices: [],
			selectedServiceProviders: [],
			saveBtnDisabled: false,
		};
	},
	mounted() {
		this.selectedServices = this.selectedServicesFromTutorInfo;
		this.selectedServiceProviders = this.selectedServiceProvidersFromTutorInfo;
	},
	computed: {
		selectedServicesFromTutorInfo() {
			if (this.tutorInfo && this.tutorInfo.services && this.tutorInfo.services.length > 0) {
				try {
					return JSON.parse(this.tutorInfo.services);
				} catch (error) {
					console.error('Error parsing services:', error);
				}
			}
			return [];
		},
		selectedServiceProvidersFromTutorInfo() {
			if (this.tutorInfo && this.tutorInfo.service_providers && this.tutorInfo.service_providers.length > 0) {
				try {
					return JSON.parse(this.tutorInfo.service_providers);
				} catch (error) {
					console.error('Error parsing service_providers:', error);
				}
			}

			return [];
		}
	},
	watch: {
		tutorInfo: {
			handler() {
				this.selectedServices = this.selectedServicesFromTutorInfo;
				this.selectedServiceProviders = this.selectedServiceProvidersFromTutorInfo;
			},
			deep: true
		}
	},
	methods: {
		goToPrevious() {
			this.$emit('go-to-previous');
		},
		isServiceChecked(serviceId) {
			return this.selectedServices.includes(serviceId);
		},
		toggleServiceCheckbox(serviceId) {
			if (this.selectedServices.includes(serviceId)) {
				this.selectedServices = this.selectedServices.filter(id => id !== serviceId);
			} else {
				this.selectedServices.push(serviceId);
			}
		},
		isServiceProviderChecked(serviceProviderId) {
			return this.selectedServiceProviders.includes(serviceProviderId);
		},
		toggleServiceProviderCheckbox(serviceProviderId) {
			if (this.selectedServiceProviders.includes(serviceProviderId)) {
				this.selectedServiceProviders = this.selectedServiceProviders.filter(id => id !== serviceProviderId);
			} else {
				this.selectedServiceProviders.push(serviceProviderId);
			}
		},
		async save() {
			this.saveBtnDisabled = true;
			this.errors = {};

			const serviceIds = this.selectedServices;
			const serviceProviderIds = this.selectedServiceProviders;

			const requestData = {
				step: 2,
				name: this.tutorInfo.name,
				nric: this.tutorInfo.nric,
				gender: this.tutorInfo.gender,
				phone: this.tutorInfo.phone,
				email: this.tutorInfo.email,
				dob: this.tutorInfo.dob,
				age: this.tutorInfo.age,
				nationality: this.tutorInfo.nationality,
				services: serviceIds,
				service_providers: serviceProviderIds
			};

			try {
				let result;

				if (this.modeName === 'Add') {
					this.saveBtnDisabled = false;
					window.alert('Please fill personal info first');
					this.goToPrevious();
				} else {
					// this.tutorInfo.services = serviceIds;
					// this.tutorInfo.service_providers = serviceProviderIds;
					result = await axios.put(`${process.env.VUE_APP_APIURL}/tutor/${this.id}`, requestData, {
						headers: authHeader(),
					});
				}

				if (result) {
					if (result.data['code'] === 'success') {
						this.saveBtnDisabled = false;
						if (this.modeName === 'Add') {
							this.$router.back();
						} else {
							this.$refs.messageModal.showModal('Your record has been updated successfully');
						}
					} else if (result.data['code'] === 'invalid_field') {
						this.saveBtnDisabled = false;
						this.errors = result.data['errors'];
					}
				}
			} catch (error) {
				this.saveBtnDisabled = false;
				console.error('Error:', error);
			}
		},
		async cancel() {
			this.$router.push({ name: "Tutor" });
		}
	},
};
</script>

<template>
	<div>
		<MessageModal ref="messageModal" />
		<div class="card" style="border-radius: 0;">
			<div class="card-body p-4" style="box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);">
				<div class="row mb-5">
					<div class="col-12">
						<label class="label">Services</label>
					</div>
					<div v-for="service in serviceOptions" :key="service.name" class="col-12 col-md-4 mt-3">                            
						<input
						type="checkbox"
						:id="'service-'+service.value"
						:name="'service-'+service.value"
						:value="service.value" :checked="selectedServices.includes(service.value)" @change="toggleServiceCheckbox(service.value)"
						/>
						<label :for="'service-'+service.value" class="ml-2 checkbox__label">{{ service.name }}</label>
					</div>

					<div class="col-12" style="margin-top: 30px;">
						<label class="label">Type of service provider</label>
					</div>
					<div v-for="service_provider in serviceProviderOptions" :key="service_provider.name" class="col-12 col-md-4 mt-3">                            
						<input
						type="checkbox"
						:id="'service_provider-'+service_provider.value"
						:name="'service_provider-'+service_provider.value"
						:value="service_provider.value" :checked="selectedServiceProviders.includes(service_provider.value)" @change="toggleServiceProviderCheckbox(service_provider.value)"
						/>
						<label :for="'service_provider-'+service_provider.value" class="ml-2 checkbox__label">{{ service_provider.name }}</label>
					</div>
				</div>

				<div v-if="modeName !== 'View'" class="divider"></div>

				<div v-if="modeName !== 'View'" class="row mt-2">
					<div class="col-12" style="text-align: right;">
						<PreviousButton @click="goToPrevious" />
						<SaveButton @click="save" :disabled="saveBtnDisabled" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>